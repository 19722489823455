import {State} from "@store/state";
import {createSelector} from "@ngrx/store";

const getUserInfos = (state: State) => state.userInfos;

export const getUserAuthorizations = createSelector(
  getUserInfos,
  (state) => state.userAuthorizations
);

export const getUserAllowedProductIdList = createSelector(
  getUserAuthorizations,
  (state) => state.userAllowedProductIdList
);

export const getIsUserAuthorizedToPushNotif = createSelector(
  getUserAuthorizations,
  (state) => state.isUserAuthorizedToPushNotif
);

export const getAllowedProductIdListIsLoading = createSelector(
  getUserAuthorizations,
  (state) => state.isLoading
);

export const getAllowedProductIdListFetchIsError = createSelector(
  getUserAuthorizations,
  (state) => state.error
);

